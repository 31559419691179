import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import Message from './Message';
import ButtonLink from './ButtonLink';
import * as Layout from './Layout';
import { useAuthContext } from './hooks';
import ResendEmailVerification from './ResendEmailVerification';

const SignUpSuccess = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'currentUser' does not exist on type 'unknown'.
  const { currentUser, hasSomeVerifiedMemberships } = useAuthContext();
  const { t } = useTranslation('onboarding');

  if (currentUser.verified) {
    return (
      <Layout.Page title={t('general.signUp')}>
        <Heading>
          {t('general.success')}
        </Heading>
        <Message type="success">
          {hasSomeVerifiedMemberships ? (
            t('signUp.accountCreatedStartUsing')
          ) : (
            t('signUp.yourAccountCreated')
          )}
        </Message>
        <Layout.Row justify="center">
          {/*
           // @ts-expect-error ts(2739) FIXME: Type '{ children: any; href: string | undefined; "data-test": string; }' is missing the following properties from type '{ [x: string]: any; outline: any; children: any; iconL: any; iconR: any; }': outline, iconL, iconR */}
          <ButtonLink href={process.env.NX_APP_URL} data-test="dashboard">
            {hasSomeVerifiedMemberships ? t('general.goToDashboard') : t('continue', { ns: 'general' })}
          </ButtonLink>
        </Layout.Row>
      </Layout.Page>
    );
  }

  return (
    <Layout.Page title={t('general.signUp')}>
      <Heading>
        {t('signUp.pleaseCheckEmail')}
      </Heading>
      <Message type="success">
        {t('signUp.accountCreated')}
      </Message>
      <Paragraph>
        {t('signUp.useEmailToLogin', { email: currentUser.email })}
      </Paragraph>
      <ResendEmailVerification />
    </Layout.Page>
  );
};

export default SignUpSuccess;
