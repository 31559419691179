// initSentry needs to be the first import
import './initSentry';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { initReactTranslations } from '@deepstream/ui/i18n';
import App from './App';

initReactTranslations()
  .then(() => {
    const container = document.getElementById('root');
    // @ts-expect-error ts(2345) FIXME: Argument of type 'HTMLElement | null' is not assignable to parameter of type 'Container'.
    const root = createRoot(container);
    root.render(<App />);
  })
  .catch(err => Sentry.captureException(err));
