import styled from 'styled-components';

export const Heading = styled.h1`
  line-height: normal;
  font-size: 30px;
  color: ${props => props.theme.colors.navy};
  margin-bottom: 14px;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  line-height: 25px;
  font-size: 16px;
  color: ${props => props.theme.colors.navy};
  margin-top: 0;
  margin-bottom: 20px;
`;

export const Normal = styled.span<{ small?: boolean }>`
  line-height: normal;
  font-size: ${props => props.small ? '14px' : '16px'};
  color: ${props => props.theme.colors.text};
`;

export const Subtle = styled(Normal)`
  color: ${props => props.theme.colors.gray};
`;

export const Success = styled.span<{ small?: boolean }>`
  line-height: normal;
  font-size: ${props => props.small ? '14px' : '16px'};
  color: ${props => props.theme.colors.success};
`;

export const Small = styled.span`
  font-size: 12px;
`;
