import { useRef, useState, useEffect } from 'react';
import { defaultsDeep, pick, set } from 'lodash';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import * as Layout from './Layout';
import * as Text from './text';
import { ErrorDisplay } from './ErrorDisplayContext';
import CompanyProfileFields from './CompanyProfileFields';
import ErrorMessage from './ErrorMessage';
import Button from './Button';
import Form from './Form';
import UnexpectedError from './UnexpectedError';
import { websiteRegExp } from './utils';
import { useApi } from './ApiProvider';

const defaultValues = {
  name: '',
  web: '',
  email: '',
  telephone: '',
  employeesNb: '',
  number: '',
  registeredName: '',
  logo: '',
  address: {
    lineOne: '',
    lineTwo: '',
    postcode: '',
    city: '',
    state: '',
    country: '',
  },
};

const companyKeys = ['_id', ...Object.keys(defaultValues)];

const UpdateCompanyForm = ({ company, setError }) => {
  const api = useApi();
  const formik = useRef();
  const { t } = useTranslation('onboarding');

  // HACK: Since we want to immediately display errors, we must immediately validate.
  useEffect(() => {
    if (company && formik.current) {
      // @ts-expect-error ts(2339) FIXME: Property 'getFormikActions' does not exist on type 'never'.
      formik.current.getFormikActions().validateForm();
    }
  }, [company]);

  return (
    <Formik
      ref={formik}
      initialValues={
        set(defaultsDeep(pick(company, companyKeys), defaultValues), 'logo', company.logoUrl)
      }
      validationSchema={
        yup.object().shape({
          name: yup.string().required(t('required', { ns: 'general' })),
          web: yup.string().matches(websiteRegExp, t('general.invalidWebsite'))
            .required(t('required', { ns: 'general' })),
          email: yup.string().email(t('general.invalidEmailAddress')).required(t('required', { ns: 'general' })),
          telephone: yup.string().matches(/^[0-9+-\s]+$/, t('general.invalidPhoneNumber')).notRequired(),
          employeesNb: yup.string().required(t('required', { ns: 'general' })),
          registeredName: yup.string().notRequired(),
          number: yup.string().notRequired(),
          logo: yup.string().notRequired(),
          address: yup.object().shape({
            lineOne: yup.string().required(t('required', { ns: 'general' })),
            lineTwo: yup.string().notRequired(),
            city: yup.string().required(t('required', { ns: 'general' })),
            state: yup.string().notRequired(),
            postcode: yup.string().required(t('required', { ns: 'general' })),
            country: yup.string().required(t('required', { ns: 'general' })),
          }),
        })
      }
      onSubmit={async (values, { setSubmitting }) => {
        try {
          await api.updateCompany(
            company._id,
            {
              ...values,
              web: values.web.toLowerCase(),
            },
          );
          setError(null);
          setSubmitting(false);
          // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'Location | (string & Location)'.
          window.location = `${process.env.NX_APP_URL}/network/${company._id}/dashboard`;
        } catch (error) {
          setSubmitting(false);
          setError(<UnexpectedError />);
          throw error;
        }
      }}
      render={({ isSubmitting, isValid }) => (
        <Form errorDisplay={ErrorDisplay.ALWAYS}>
          <CompanyProfileFields companyId={company._id} />
          <Layout.Row justify="flex-end" style={{ marginTop: 40 }}>
            <Button type="submit" iconR="arrow-right" disabled={!isValid || isSubmitting}>
              {t('general.saveAndContinue')}
            </Button>
          </Layout.Row>
        </Form>
      )}
    />
  );
};

const UpdateCompany = ({ match }) => {
  const api = useApi();
  const [error, setError] = useState(null);
  const [company, setCompany] = useState(null);
  const { t } = useTranslation('onboarding');
  const { companyId } = match.params;

  useEffect(() => {
    if (companyId) {
      api
      .getCompany(companyId)
      .then(setCompany)
      .catch(error => setError(error));
    }
  }, [api, companyId]);

  return (
    <Layout.Page title={t('updateCompany.updateCompanyProfile')} wide>
      <Text.Heading>
        {t('updateCompany.yourCompanyProfile')}
      </Text.Heading>
      <Text.Paragraph style={{ marginBottom: 30 }}>
        {t('updateCompany.requiredFields')}
      </Text.Paragraph>
      <ErrorMessage panel error={error} />
      {company ? (
        <UpdateCompanyForm company={company} setError={setError} />
      ) : (
        <span>{t('general.loadingEllipsis')}</span>
      )}
    </Layout.Page>
  );
};

export default UpdateCompany;
