import { useTranslation } from 'react-i18next';

import { Heading } from './text';
import * as Layout from './Layout';
import ButtonLink from './ButtonLink';
import Message from './Message';
import { useAuthContext } from './hooks';

const EmailVerified = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'isAuthenticated' does not exist on type 'unknown'.
  const { isAuthenticated, hasSomeVerifiedMemberships } = useAuthContext();
  const { t } = useTranslation('onboarding');

  return (
    <Layout.Page title={t('emailVerification.verifiedAccount')}>
      <Heading>
        {t('general.success')}
      </Heading>
      <Message type="success">
        {t('emailVerification.yourEmailAddressVerified')}
      </Message>
      <Layout.Row justify="center">
        {!isAuthenticated ? (
          <ButtonLink to="/">{t('continue', { ns: 'general' })}</ButtonLink>
        ) : hasSomeVerifiedMemberships ? (
          // @ts-expect-error ts(2741) FIXME: Property 'to' is missing in type '{ children: ((string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | Iterable<...>) & (string | ... 4 more ... | ReactPortal)) | null | undefined; href: string | undefined; "data-test": string; }' but required in type 'LinkProps<unknown>'.
          <ButtonLink href={process.env.NX_APP_URL} data-test="dashboard">
            {t('continue', { ns: 'general' })}
          </ButtonLink>
        ) : (
          <ButtonLink to="/find-company" data-test="dashboard">
            {t('continue', { ns: 'general' })}
          </ButtonLink>
        )}
      </Layout.Row>
    </Layout.Page>
  );
};

export default EmailVerified;
