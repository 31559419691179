import { useEffect } from 'react';
import { find, isEmpty } from 'lodash';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import { wrap } from '@deepstream/ui/api';
import { useAuthContext, useIsAppAdmin } from './hooks';
import ErrorMessage from './ErrorMessage';
import { useLatestCompanyId } from './useLatestCompanyId';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { useApi } from './ApiProvider';
import { LS_REDIRECT_KEY } from './apiClient';
import { hasIncompleteData } from './userUtils';

export const LoginRedirect = ({ history }) => {
  const api = useApi();
  const { t } = useTranslation('onboarding');

  const {
    // @ts-expect-error ts(2339) FIXME: Property 'currentUser' does not exist on type 'unknown'.
    currentUser,
    // @ts-expect-error ts(2339) FIXME: Property 'hasOnlyPendingMemberships' does not exist on type 'unknown'.
    hasOnlyPendingMemberships,
    // @ts-expect-error ts(2339) FIXME: Property 'hasSomeVerifiedMemberships' does not exist on type 'unknown'.
    hasSomeVerifiedMemberships,
  } = useAuthContext();
  const systemFeatureFlags = useSystemFeatureFlags();
  const companyId = useLatestCompanyId();
  const isAppAdmin = useIsAppAdmin();

  const { data: company, isError: couldNotLoadCompany } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany.bind(api)),
    {
      enabled: !!companyId,
    },
  );

  const dataHasLoaded = (
    // `company` only gets loaded when there's a `companyId`.
    (!companyId || company)
  );

  const canEdit = (
    companyId &&
    find(currentUser.companyRoles, { _id: companyId }).editor
  );

  const shouldRedirectToUpdateProductTagsWizard = (
    companyId &&
    company &&
    canEdit &&
    !systemFeatureFlags.productTagMigrationDone &&
    !isEmpty(company.products) &&
    isEmpty(company.productsAndServices) &&
    !currentUser.preferences.hideProductTagMigrationReminder
  );

  useEffect(() => {
    if (!currentUser.verified) {
      history.replace('/signup/success');
    } else if (systemFeatureFlags.requiredTermsAndNameEnabled && hasIncompleteData(currentUser)) {
      history.replace('/incomplete');
    } else if (hasSomeVerifiedMemberships) {
      if (!dataHasLoaded) {
        // Don't redirect as long as data loading hasn't completed.
      } else if (shouldRedirectToUpdateProductTagsWizard) {
        history.replace(`/company/${companyId}/update-product-tags`);
      } else {
        const { redirectURL, shouldBeAdmin } = JSON.parse(localStorage.getItem(LS_REDIRECT_KEY) || '{}');
        if (redirectURL && shouldBeAdmin && !isAppAdmin) {
          history.replace('/forbidden');
        } else if (redirectURL) {
          // Append a fake query param to bust the browser cache
          // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'Location | (string & Location)'.
          window.location = `${redirectURL}?t=${Date.now()}`;
        } else {
          // In case we couldn't determine a `companyId`, let the main app
          // try to determine it.
          // Append a fake query param to bust the browser cache
          // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'Location | (string & Location)'.
          window.location = companyId
            ? `${process.env.NX_APP_URL}/network/${companyId}/dashboard?t=${Date.now()}`
            : `${process.env.NX_APP_URL}/network?t=${Date.now()}`;
        }
        localStorage.removeItem(LS_REDIRECT_KEY);
      }
    } else if (hasOnlyPendingMemberships) {
      history.replace('/verification');
    } else {
      history.replace('/find-company');
    }
  }, [
    dataHasLoaded,
    companyId,
    shouldRedirectToUpdateProductTagsWizard,
    hasOnlyPendingMemberships,
    hasSomeVerifiedMemberships,
    history,
    currentUser,
    systemFeatureFlags.requiredTermsAndNameEnabled,
    isAppAdmin,
  ]);

  return couldNotLoadCompany ? (
    <ErrorMessage panel error={t('general.couldNotLoadCompany')} />
  ) : (
    null
  );
};
