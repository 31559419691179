import { useTranslation } from 'react-i18next';

import Button from './Button';
import { useAuthContext } from './hooks';

const LogOutButton = ({ small = true, outline = true }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'logout' does not exist on type 'unknown'.
  const { logout } = useAuthContext();
  const { t } = useTranslation('onboarding');

  return (
    <Button outline={outline} type="button" small={small} onClick={logout}>
      {t('general.logOut')}
    </Button>
  );
};

export default LogOutButton;
