import { useTranslation } from 'react-i18next';
import { getLocaleCookie } from '@deepstream/ui/cookie';

import { DEFAULT_LOCALE } from '@deepstream/common/user-utils';

import * as Layout from './Layout';
import * as Text from './text';
import { LoginRedirect } from './LoginRedirect';
import { useAuthContext } from './hooks';
import { LoginButton } from './LoginButton';
import LinkButton from './LinkButton';
import ErrorMessage from './ErrorMessage';

export const Landing = ({ history }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'isAuthenticated' does not exist on type 'unknown'.
  const { isAuthenticated, loginWithRedirect, error } = useAuthContext();
  const { t } = useTranslation('onboarding');

  const handleSignup = () => {
    const locale = getLocaleCookie() || DEFAULT_LOCALE;
    loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        ui_locales: locale,
      },
    });
  };

  return (
    <Layout.Page title={t('general.logIn')}>
      {isAuthenticated ? (
        <LoginRedirect history={history} />
      ) : (
        <>
          <Text.Heading style={{ marginBottom: 30 }}>
            {t('general.welcome')}
          </Text.Heading>
          {error ? (
            <ErrorMessage panel error={`${t('landing.authenticationError')}: ${error.message}`} />
          ) : null}
          <Text.Normal>
            {t('landing.logInOrCreateAccount')}
          </Text.Normal>
          <Layout.Row justify="flex-end" style={{ marginTop: 40, gap: 20 }}>
            <LinkButton onClick={handleSignup} style={{ textDecoration: 'underline' }}>
              {t('signUp.createAccount')}
            </LinkButton>
            <LoginButton />
          </Layout.Row>
        </>
      )}
    </Layout.Page>
  );
};
