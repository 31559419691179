import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle<any>`
  * {
    box-sizing: border-box;
  }

  html, body, .viewport {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: ${props => props.theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a:focus, button:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.35);
  }

  textarea {
    font-family: ${props => props.theme.fonts.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;

export default GlobalStyles;
