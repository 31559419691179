import { find } from 'lodash';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CurrentCompanyIdContext } from '@deepstream/ui/currentCompanyId';
import { ClientThemeProvider } from '@deepstream/ui/ClientThemeProvider';
import { wrap, ApiProvider } from '@deepstream/ui/api';
import { ModalStyles } from '@deepstream/ui/GlobalStyles';
import { ToastProvider } from '@deepstream/ui/toast';
import { EnvProvider } from '@deepstream/ui/env';
import { useQuery } from 'react-query';
import { useAuthContext } from './hooks';
import Message from './Message';
import ErrorMessage from './ErrorMessage';
import * as Layout from './Layout';
import { Heading } from './updateProductTags';
import { updateProductTagsMachine } from './updateProductTagsMachine';
import { Wizard } from './Wizard';
import { useSystemFeatureFlags } from './systemFeatureFlags';
import { useApi } from './ApiProvider';

export const UpdateProductTagsWizard = withRouter(({ match, history }) => {
  const api = useApi();
  const { companyId, stepId } = match.params;
  // @ts-expect-error ts(2339) FIXME: Property 'currentUser' does not exist on type 'unknown'.
  const { currentUser } = useAuthContext();
  const systemFeatureFlags = useSystemFeatureFlags();
  const { t } = useTranslation('onboarding');

  const currentCompanyRoles = currentUser.companyRoles && find(currentUser.companyRoles, { _id: companyId });

  const { data: company, isError: couldNotLoadCompany } = useQuery(
    ['publicCompany', { companyId }],
    wrap(api.getPublicCompany.bind(api)),
    {
      enabled: !!companyId,
    },
  );

  const navigateToStep = (stepId) =>
    history.push(`/company/${companyId}/update-product-tags/${stepId}`);

  const env = {
    HELP_CENTER_URL: process.env.NX_HELP_CENTER_URL,
  };

  return (
    <Layout.Page wide title={t('updateProductTags.updateYourProductsAndTags')}>
      <ClientThemeProvider>
        <ToastProvider>
          <ModalStyles />
          <Heading>
            {t('updateProductTags.updateYourProductsAndTags')}
          </Heading>
          {(!currentCompanyRoles || !currentCompanyRoles.editor) ? (
            <ErrorMessage panel error={t('forbidden.accessDenied')} />
          ) : (systemFeatureFlags.productTagMigrationDone) ? (
            <Message type="warning">
              {t('updateProductTags.featureDisabled')}
            </Message>
          ) : couldNotLoadCompany ? (
            <ErrorMessage panel error={t('general.couldNotLoadCompany')} />
          ) : (systemFeatureFlags && company) ? (
            // @ts-expect-error ts(2739) FIXME: Type '{ HELP_CENTER_URL: string | undefined; }' is missing the following properties from type 'Env': ENV, API_URL, PUBLIC_PAGES_URL
            <EnvProvider env={env}>
              <ApiProvider baseUrl={process.env.NX_API_URL}>
                <CurrentCompanyIdContext.Provider value={companyId}>
                  <Wizard
                    key={stepId}
                    machine={updateProductTagsMachine}
                    stepId={stepId}
                    navigateToStep={navigateToStep}
                    company={company}
                  />
                </CurrentCompanyIdContext.Provider>
              </ApiProvider>
            </EnvProvider>
          ) : (
            null
          )}
        </ToastProvider>
      </ClientThemeProvider>
    </Layout.Page>
  );
});
