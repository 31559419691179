import axios, { AxiosInstance } from 'axios';
import { assign, noop } from 'lodash';

const LOCAL_STORAGE_KEY = 'onboardingData';
export const LS_REDIRECT_KEY = '@ds/redirectUrl';
export const APP_COMPANY_ID = '_app';

/*
 * @returns {import('@deepstream/common/company').OnboardingCompanyInformation}
 * */
function extractCompanyInformation(onboardingData) {
  const company = {
    _id: onboardingData._id,
    address: onboardingData.address,
    email: onboardingData.email,
    telephone: onboardingData.telephone,
    employeesNb: onboardingData.employeesNb,
    logo: onboardingData.logo,
    web: onboardingData.web,
    name: onboardingData.name,
    number: onboardingData.number,
    registeredName: onboardingData.registeredName,
    statement: onboardingData.description,
    group: onboardingData.companyType,
    productsAndServices: onboardingData.productsAndServices,
  };

  return company;
}

export class ApiClient {
  instance: AxiosInstance;
  tokenGenerator?: any;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.NX_API_URL,
      withCredentials: true,
      withXSRFToken: (config) => Boolean(config.withCredentials),
    });

    this.instance.interceptors.request.use(
      // @ts-expect-error ts(2345) FIXME: Argument of type '(config: InternalAxiosRequestConfig<any>) => Promise<InternalAxiosRequestConfig<any> | { headers: { Authorization: string; ... 4 more ...; 'Content-Type'?: ContentType | undefined; }; ... 40 more ...; fetchOptions?: Record<...> | undefined; }>' is not assignable to parameter of type '(value: InternalAxiosRequestConfig<any>) => InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>>'.
      async config => {
        if (!config.withCredentials) {
          return config;
        }

        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      error => {
        Promise.reject(error);
      },
    );

    this.instance.interceptors.response.use(response => response.data);
  }

  setTokenGenerator(tokenGenerator) {
    this.tokenGenerator = tokenGenerator;
  }

  getToken() {
    return this.tokenGenerator
      ? this.tokenGenerator()
      : noop;
  }

  getMe() {
    return this.instance.get('/ajax/currentUser');
  }

  findCompaniesAvailableToJoin(text) {
    return this.instance.get(`/ajax/companies/availableToJoin?text=${text}`);
  }

  getProductTags() {
    return this.instance.get('/ajax/productTag');
  }

  getCompanyVerification() {
    return this.instance.get('/ajax/pendingCompanies/users');
  }

  getCompany(id) {
    return this.instance.get(`/ajax/company/${id}`);
  }

  getCompanyAdmins(id) {
    return this.instance.get(`/ajax/company/${id}/admins`);
  }

  updateCompany(id, patch) {
    return this.instance.put(`/ajax/company/${id}`, patch);
  }

  updateUserProfile(patch) {
    return this.instance.put('/ajax/user', patch);
  }

  async submitCompanyForVerification() {
    // @ts-expect-error ts(2345) FIXME: Argument of type 'string | null' is not assignable to parameter of type 'string'.
    const company = extractCompanyInformation(JSON.parse(localStorage.getItem('onboardingData')));
    await this.instance.post('/ajax/company', company);
    localStorage.removeItem('onboardingData');
  }

  requestCompanyMembership(company, jobTitle, message) {
    // TODO remove `?join=true` -- it's redundant
    return this.instance.post('/ajax/companyAction?join=true', {
      company,
      comment: message,
      role: jobTitle,
    });
  }

  sendVerifyEmail() {
    return this.instance.get('/ajax/verify/email');
  }

  isValidBrowser() {
    return this.instance.get('/ajax/isValidBrowser', { withCredentials: false });
  }

  uploadLogo(companyId, imageFile) {
    const formData = new FormData();

    formData.append('logo', imageFile);

    return this.instance
      .post(`/ajax/temp-logos?purpose=logo&companyId=${companyId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  }

  getNextCompanyId() {
    return this.instance.post('/ajax/companies/id');
  }

  getCountryCodes() {
    return this.instance.get('/ajax/countryCodes', { withCredentials: false });
  }

  getSystemFeatureFlags() {
    return this.instance.get('/ajax/featureFlags', { withCredentials: false });
  }

  getPublicCompany({ companyId }) {
    return this.instance.get(`/ajax/company/${companyId}`);
  }

  getLiveTermsOfService() {
    return this.instance.get('/ajax/termsOfService');
  }

  acceptTermsOfService(termsVersion) {
    return this.instance.put('/ajax/user/termsOfService', { termsVersion });
  }

  getEnabledLanguages = () => {
    return this.instance.get('/ajax/admin/enabledLanguages');
  };

  postActiveSession = (context, companyId, client) => this.instance.post('/ajax/session/active/v2', { context, companyId, client });
}

export const saveOnboardingProgress = (values) => {
  // TODO - Make this not a shitty hacky local-storage thing
  const initialData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}');
  const updatedData = assign({}, initialData, values);
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedData));
};

export const loadOnboardingProgress = () => {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY);
  return Promise.resolve(data ? JSON.parse(data) : null);
};
