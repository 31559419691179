import { Link as RouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

/* Wraps react-router's Link component with proper styling */

const styles = css<{ primary?: boolean; underline?: boolean }>`
  color: ${props => props.primary ? props.theme.colors.primary : props.theme.colors.gray};
  font-size: 14px;
  text-decoration-line: ${props => props.underline ? 'underline' : 'none'};
`;

const StyledRouterLink = styled(RouterLink)`
  ${styles}
`;

const StyledAnchor = styled.a`
  ${styles}
`;

const Link = (props) => {
  if (props.href) {
    return <StyledAnchor {...props} />;
  } else if (props.to) {
    return <StyledRouterLink {...props} />;
  } else {
    throw new Error('Props are missing either a `to` or `href`');
  }
};

export default Link;
