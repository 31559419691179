import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties, ReactNode } from 'react';
import { Row, Cell } from './Layout';

const MessageContainer = styled.div<{ type: string }>`
  border-radius: ${props => props.theme.border.radii.sharp};
  border: ${props => props.theme.border.width} solid ${props => props.theme.colors.lightGray};
  border-left-width: 5px;
  border-left-color: ${props => props.theme.colors[props.type]};

  margin-top: 28px;
  margin-bottom: 28px;

  padding-top: 18px;
  padding-bottom: 18px;
  padding-right: 22px;

  line-height: 25px;
  color: ${props => props.theme.colors[props.type]};
`;

const Icon = styled(FontAwesomeIcon)<{ type: string }>`
  color: ${props => props.theme.colors[props.type]};
`;

const typeToIcon = {
  danger: 'times-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
  info: 'info-circle',
};

const Message = ({
  type = 'info',
  children,
  style,
}: {
  type?: string;
  children?: ReactNode;
  style?: CSSProperties;
}) => (
  <MessageContainer type={type} style={style}>
    <Row align="center">
      <Cell style={{ marginLeft: 20, marginRight: 20 }}>
        <Icon type={type} icon={typeToIcon[type]} />
      </Cell>
      <Cell>
        {children}
      </Cell>
    </Row>
  </MessageContainer>
);

export default Message;
