import { useMemo, useEffect } from 'react';
import { find, first, isEmpty, reject } from 'lodash';
import { useAuthContext } from './hooks';
import { APP_COMPANY_ID } from './apiClient';

const LOCAL_STORAGE_COMPANY_KEY = 'ekCurrentCompanyId';

export const useLatestCompanyId = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'currentUser' does not exist on type 'unknown'.
  const { currentUser } = useAuthContext();

  const companyId = useMemo(() => {
    if (isEmpty(currentUser.companyRoles)) {
      return null;
    }

    const companyRolesWithoutApp = reject(currentUser.companyRoles, { _id: APP_COMPANY_ID });

    const latestCompanyId = localStorage.getItem(LOCAL_STORAGE_COMPANY_KEY);
    if (latestCompanyId && find(companyRolesWithoutApp, { _id: latestCompanyId })) {
      return latestCompanyId;
    }

    const randomCompany = find(companyRolesWithoutApp, cr => !cr.hasSentRequest) ||
      first(companyRolesWithoutApp);

    return randomCompany ? randomCompany._id : null;
  }, [currentUser]);

  useEffect(() => {
    if (companyId) {
      localStorage.setItem(LOCAL_STORAGE_COMPANY_KEY, companyId);
    }
  }, [companyId]);

  return companyId;
};
