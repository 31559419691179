import { useEffect } from 'react';
import { scrollToTop } from '@deepstream/ui-utils/scrollToTop';

const ScrollToTop = () => {
  useEffect(
    () => {
      scrollToTop();
    },
    [],
  );

  return null;
};

export default ScrollToTop;
