import { merge } from 'lodash';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { IconText } from '@deepstream/ui-kit/elements/text/IconText';
import { useTranslation } from 'react-i18next';

import { Heading, Paragraph } from './text';
import WizardNav from './WizardNav';
import Fieldset from './Fieldset';
import Field from './Field';
import Legend from './Legend';
import ErrorMessage from './ErrorMessage';
import Title from './Title';
import ProductPickerField from './ProductPickerField';
import AutoSave from './AutoSave';
import ScrollToTop from './ScrollToTop';
import { Row } from './Layout';

const initialValues = {
  description: '',
  regions: [],
  productsAndServices: [],
};

const DescribeCompany = ({ wizardState, stepIndex, numSteps, error, updateStep, prevStep, nextStep }) => {
  const { t } = useTranslation('onboarding');

  if (!wizardState) {
    return (
      <div>
        <ErrorMessage panel error={t('createCompany.couldNotGetCompanyData')} />
        {/*
         // @ts-expect-error ts(2741) FIXME: Property 'disabled' is missing in type '{ stepIndex: any; numSteps: any; canContinue: false; goBack: () => void; }' but required in type '{ stepIndex: any; numSteps: any; disabled: any; canContinue?: boolean | undefined; goBack: any; }'. */}
        <WizardNav
          stepIndex={stepIndex}
          numSteps={numSteps}
          canContinue={false}
          goBack={() => {
            prevStep();
          }}
        />
      </div>
    );
  }

  return (
    <div key={wizardState.companyType}>
      <Title text={t('createCompany.buildYourProfile')} />
      <ScrollToTop />
      <Heading>{t('createCompany.buildYourProfile')}</Heading>
      <Paragraph>
        {t('createCompany.tellDeepStreamNetwork')}
      </Paragraph>
      <ErrorMessage panel error={error} />
      <Formik
        initialValues={merge({}, initialValues, wizardState)}
        validationSchema={
          yup.object().shape({
            description: yup.string().notRequired(), // gets mapped to `company.statement`
            regions: yup.array(),
            productsAndServices: ['supplier', 'both'].includes(wizardState.companyType)
              ? yup.array().min(1)
              : yup.array(),
          })
        }
        onSubmit={values => {
          updateStep(values);
          nextStep();
        }}
        render={({ values, isSubmitting, isValid }) => (
          <Form>
            <ErrorMessage panel error={error} />
            <Field label={t('createCompany.companyDescription')} name="description" type="textarea" />
            {['supplier', 'both'].includes(wizardState.companyType) && (
              <Fieldset>
                <Legend>{t('createCompany.productsAndServices')}</Legend>
                <ProductPickerField />
              </Fieldset>
            )}
            <WizardNav
              stepIndex={stepIndex}
              numSteps={numSteps}
              disabled={isSubmitting}
              canContinue={isValid}
              goBack={() => {
                updateStep(initialValues);
                prevStep();
              }}
            />
            {!isValid ? (
              <Row align="center" justify="end" style={{ padding: '16px 0' }}>
                <IconText
                  icon="info-circle"
                  text={t('createCompany.selectOneProduct')}
                />
              </Row>
            ) : null}
            <AutoSave
              debounceMs={2000}
              values={values}
              onSave={updateStep}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default DescribeCompany;
