import { range } from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { Row } from './Layout';
import Circle from './Circle';

const WizardNavDot = styled(Circle)<{ active?: boolean }>`
  width: 9px;
  height: 9px;
  margin-right: 10px;
  background-color: ${props => props.active
    ? props.theme.colors.primary
    : props.theme.colors.lightGray2};
`;

export const WizardNavDots = ({ numSteps, stepIndex }) => (
  <span>
    {range(0, numSteps).map(n => (
      <WizardNavDot
        key={n}
        active={n === stepIndex}
      />
    ))}
  </span>
);

const BackButton = ({ onClick, disabled }) => {
  const { t } = useTranslation('general');

  return (
    <Button secondary type="button" onClick={onClick} disabled={disabled} iconL="arrow-left">
      {t('back')}
    </Button>
  );
};

const ContinueButton = ({ disabled }) => {
  const { t } = useTranslation('general');

  return (
    <Button type="submit" disabled={disabled} iconR="arrow-right">
      {t('continue')}
    </Button>
  );
};

const WizardNav = ({ stepIndex, numSteps, disabled, canContinue = true, goBack }) => (
  <Row align="center" justify="space-between">
    <BackButton
      onClick={goBack}
      disabled={disabled}
    />
    <WizardNavDots
      stepIndex={stepIndex}
      numSteps={numSteps}
    />
    <ContinueButton disabled={disabled || !canContinue} />
  </Row>
);

export default WizardNav;
