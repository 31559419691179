class AuthBridge {
  private origin: string;
  private iframe: HTMLIFrameElement;
  private iframeReadyPromise: any;
  private resolveIframeReadyPromise?: any;
  private resolveTokenReceivedPromise?: any;
  private resolveTokenRemovedPromise?: any;

  constructor(origin: string, path: string) {
    this.origin = origin;
    this.iframe = document.createElement('iframe');
    this.iframe.style.display = 'none';
    this.iframe.src = `${this.origin}/${path}`;
    if (document.readyState === 'complete') {
      document.body.appendChild(this.iframe);
    } else {
      window.addEventListener('load', () => document.body.appendChild(this.iframe));
    }
    window.addEventListener('message', message => this.handleMessage(message));
    this.iframeReadyPromise = this.waitForIframe();
  }

  public async sendToken(token) {
    if (await this.isReady()) {
      const sendToken = new Promise((resolve) => {
        this.resolveTokenReceivedPromise = resolve;
        this.sendEvent('token', token);
      });

      const result = await this.wrapWithTimeout(sendToken);
      if (result === 'timeout') {
        // eslint-disable-next-line no-console
        console.error(`Send token for ${this.origin} timed out.`);
      }
    }

    return Promise.resolve();
  }

  public async removeToken() {
    const ready = await this.isReady();
    if (ready) {
      const removeToken = new Promise((resolve) => {
        this.resolveTokenRemovedPromise = resolve;
        // @ts-expect-error ts(2554) FIXME: Expected 2 arguments, but got 1.
        this.sendEvent('removeToken');
      });

      const result = await this.wrapWithTimeout(removeToken);
      if (result === 'timeout') {
        // eslint-disable-next-line no-console
        console.error(`Remove token for ${this.origin} timed out.`);
      }
    }

    return Promise.resolve();
  }

  private handleMessage(message) {
    const { data: { event }, origin } = message;
    if (origin === this.origin && event) {
      switch (event) {
        case 'iframeReady':
          this.resolveIframeReadyPromise();
          break;
        case 'tokenReceived':
          this.resolveTokenReceivedPromise();
          break;
        case 'tokenRemoved':
          this.resolveTokenRemovedPromise();
          break;
        default:
          // eslint-disable-next-line no-console
          console.error('Event unrecognized');
      }
    }
  }

  private waitForIframe() {
    const iframeWait = new Promise((resolve) => {
      this.resolveIframeReadyPromise = resolve;
    });

    return this.wrapWithTimeout(iframeWait);
  }

  private sendEvent(message, token) {
    this.iframe.contentWindow?.postMessage(
      { event: message, token },
      this.origin,
    );
  }

  private wrapWithTimeout(promise) {
    const timeout = new Promise((resolve) => {
      setTimeout(resolve, 4000, 'timeout');
    });

    return Promise.race([timeout, promise]);
  }

  async isReady() {
    const result = await this.iframeReadyPromise;
    if (result === 'timeout') {
      // eslint-disable-next-line no-console
      console.error(`Cannot message origin ${this.origin}. Did you forget to start the project?`);
      return false;
    }
    return true;
  }
}

class AuthBridgeRegistry {
  private map: Map<string, AuthBridge>;

  constructor() {
    this.map = new Map<string, AuthBridge>();
  }

  public getBridge(origin: string, path: string): AuthBridge {
    if (!this.map.has(origin)) {
      const bridge = new AuthBridge(origin, path);
      this.map.set(origin, bridge);
    }
    return this.map.get(origin)!;
  }
}

export const authBridgeRegistry = new AuthBridgeRegistry();
