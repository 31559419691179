import { some } from 'lodash';
import { APP_COMPANY_ID } from '../apiClient';
import useAuthContext from './useAuthContext';

const useIsAppAdmin = () => {
  // @ts-expect-error ts(2339) FIXME: Property 'currentUser' does not exist on type 'unknown'.
  const { currentUser } = useAuthContext();
  return some(currentUser.companyRoles, { _id: APP_COMPANY_ID, admin: true });
};

export default useIsAppAdmin;
