import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MouseEventHandler, ReactNode } from 'react';
import Button from './Button';

export const navigateToDashboard = (companyId) => {
  // @ts-expect-error ts(2322) FIXME: Type 'string' is not assignable to type 'Location | (string & Location)'.
  window.location = `${process.env.NX_APP_URL}/network/${companyId}/dashboard`;
};

export const Heading = styled.h1`
  line-height: normal;
  font-size: 30px;
  font-weight: 500;
  color: #000;
  margin-bottom: 28px;
`;

export const SubHeading = styled.h2`
  line-height: 25px;
  font-size: 20px;
  color: #000;
  margin-bottom: 20px;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  line-height: 25px;
  font-size: 16px;
  color: #000;
  margin-top: 0;
  margin-bottom: 16px;
`;

export const ExternalLink = styled.a`
  color: #000;
  font-weight: 500;
`;

export const Strong = styled.strong`
  font-weight: 500;
`;

export const BackButton = ({
  onClick,
  disabled,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('general');

  return (
    <Button secondary type="button" onClick={onClick} disabled={disabled} iconL="arrow-left">
      {t('back')}
    </Button>
  );
};

export const ContinueButton = ({
  onClick,
  disabled,
  text,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  text?: ReactNode;
}) => {
  const { t } = useTranslation('general');

  return (
    <Button type="button" onClick={onClick} disabled={disabled} iconR="arrow-right">
      {text || t('continue')}
    </Button>
  );
};

export const DoneButton = ({
  onClick,
  disabled,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}) => {
  const { t } = useTranslation('general');

  return (
    <Button type="button" onClick={onClick} disabled={disabled}>
      {t('done')}
    </Button>
  );
};

export const SecondaryButton = ({
  onClick,
  disabled,
  text,
}: {
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  text?: ReactNode;
}) => (
  <Button
    secondary
    type="button"
    onClick={onClick}
    disabled={disabled}
    style={{ marginRight: 10 }}
  >
    {text}
  </Button>
);

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 18px;
`;
