import { CSSProperties, ReactNode, useContext } from 'react';
import { camelCase, get } from 'lodash';
import { Field as FormikField } from 'formik';
import Input from './Input';
import { ErrorDisplay, ErrorDisplayContext } from './ErrorDisplayContext';

/* Wraps our custom Input component with the Field component provided by Formik */

const Field = ({
  label,
  name,
  type,
  placeholder,
  checkStrength,
  isRequired,
  helperText,
  disabled,
  children,
  style = {},
}: {
  label: string;
  name: string;
  type?: string;
  placeholder?: string;
  checkStrength?: boolean;
  isRequired?: boolean;
  helperText?: string;
  disabled?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
  autoCapitalize?: string;
}) => {
  // @ts-expect-error ts(2339) FIXME: Property 'errorDisplay' does not exist on type 'String'.
  const { errorDisplay } = useContext(ErrorDisplayContext);

  return (
    <FormikField
      id={camelCase(name)}
      name={name}
      render={({ field, form }) => (
        <Input
          {...field}
          id={name}
          type={type}
          label={label}
          placeholder={placeholder}
          checkStrength={checkStrength}
          isRequired={isRequired}
          helperText={helperText}
          style={({ marginBottom: 20, ...style })}
          error={
            // Using `_.get` because the `name` might represent a nested object's path
            errorDisplay === ErrorDisplay.ALWAYS
              ? get(form.errors, name)
              : get(form.touched, name) && get(form.errors, name)
          }
          disabled={disabled}
        >
          {children}
        </Input>
      )}
    />
  );
};

export default Field;
