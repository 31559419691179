import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewProductsPanel } from '@deepstream/ui/ViewProductsPanel';
import { StructuredTags } from '@deepstream/ui/ui/StructuredTags';
import { EditProductsModal } from '@deepstream/ui/EditProductsModal';
import { EditButton } from '@deepstream/ui-kit/elements/button/Button';
import { useModalState } from '@deepstream/ui/ui/useModalState';
import { useTheme } from '@deepstream/ui-kit/theme/ThemeProvider';
import {
  Panel,
  PanelDivider,
  PanelHeader,
  PanelPadding,
  PanelText,
} from '@deepstream/ui-kit/elements/Panel';
import { createDeprecatedProductTagTree } from '@deepstream/ui/utils';
import {
  ExternalLink,
  Paragraph,
  SubHeading,
  BackButton,
  ContinueButton,
  ButtonRow,
} from './updateProductTags';

const SectionMessage = ({ text }) => {
  const theme = useTheme();

  return (
    <PanelText style={{ color: theme.colors.text, padding: 0 }} fontSize={3}>
      {text}
    </PanelText>
  );
};

const SectionPanel = ({
  title,
  actionButton,
  children,
  ...props
}) => (
  <Panel mt="28px" mb="36px" {...props}>
    <PanelHeader heading={title}>
      {actionButton}
    </PanelHeader>
    <PanelDivider />
    {children}
  </Panel>
);

export const UpdateProductTagsEditStep = ({ send, company }) => {
  const editProductsModal = useModalState();
  const { t } = useTranslation('onboarding');

  const deprecatedTags = useMemo(
    () => createDeprecatedProductTagTree(company.products),
    [company],
  );

  return (
    <>
      <div>
        <SubHeading>
          {t('updateProductTags.reviewExistingTags')}
        </SubHeading>
        <Paragraph>
          {t('updateProductTags.seeExistingTags')}
        </Paragraph>
        {/*
         // @ts-expect-error ts(2741) FIXME: Property 'actionButton' is missing in type '{ children: Element; title: any; }' but required in type '{ [x: string]: any; title: any; actionButton: any; children: any; }'. */}
        <SectionPanel title={t('general.productsAndServices')}>
          <PanelPadding maxHeight="428px" overflowY="auto">
            {isEmpty(deprecatedTags) ? (
              <SectionMessage text={t('updateProductTags.noTags')} />
            ) : (
              <StructuredTags tags={deprecatedTags} mb={-3} />
            )}
          </PanelPadding>
        </SectionPanel>
      </div>
      <div>
        <SubHeading>
          {t('updateProductTags.selectNewTags')}
        </SubHeading>
        <Paragraph>
          <Trans t={t} i18nKey="updateProductTags.selectTag">
            {' '}
            <ExternalLink href="https://www.unspsc.org/" target="_blank" />
          </Trans>
        </Paragraph>
        <SectionPanel
          title={t('general.productsAndServices')}
          actionButton={<EditButton small onClick={editProductsModal.open} />}
          mb="24px"
        >
          <PanelPadding height="428px" overflowY="auto" fontSize={2}>
            {isEmpty(company.productsAndServices) ? (
              <SectionMessage text={t('updateProductTags.noTagsSelected')} />
            ) : (
              <ViewProductsPanel productsAndServices={company.productsAndServices} />
            )}
          </PanelPadding>
        </SectionPanel>
      </div>
      <ButtonRow>
        <BackButton onClick={() => send('PREV_STEP')} />
        <ContinueButton
          onClick={() => send('NEXT_STEP')}
          disabled={isEmpty(company.productsAndServices)}
        />
      </ButtonRow>
      {isEmpty(company.productsAndServices) && (
        <Paragraph style={{ marginTop: 30, textAlign: 'right' }}>
          <FontAwesomeIcon icon="info-circle" />{' '}
          {t('updateProductTags.selectAtLeastOneNewTag')}
        </Paragraph>
      )}
      {editProductsModal.isOpen && (
        <EditProductsModal
          onSave={editProductsModal.close}
          onCancel={editProductsModal.close}
          isOpen={editProductsModal.isOpen}
          company={company}
        />
      )}
    </>
  );
};
