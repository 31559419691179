import { useEffect } from 'react';
import { Loading } from '@deepstream/ui/ui/Loading';
import { useLocation } from 'react-router-dom';
import { useAuthContext } from './hooks';
import { SimpleSignUp } from './local-auth/SimpleSignUp';
import { isLocalAuth } from './auth/isLocalAuth';

export function SignUp() {
  return isLocalAuth()
    ? <SimpleSignUp />
    : <Auth0SignUp />;
}

export function Auth0SignUp() {
  // @ts-expect-error ts(2339) FIXME: Property 'loginWithRedirect' does not exist on type 'unknown'.
  const { loginWithRedirect } = useAuthContext();
  const location = useLocation();

  useEffect(
    () => {
      const searchParams = new URLSearchParams(location.search);
      const email = searchParams.get('email');
      const locale = searchParams.get('locale');

      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          login_hint: email,
          ui_locales: locale,
        },
      });
    },
    [location.search, loginWithRedirect],
  );

  return (
    <Loading />
  );
}
