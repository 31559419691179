import { useEffect } from 'react';
import { getLocaleCookie } from '@deepstream/ui/cookie';

import { DEFAULT_LOCALE } from '@deepstream/common/user-utils';

import { useAuthContext } from './hooks';
import { SimpleLogin } from './local-auth/SimpleLogin';
import { isLocalAuth } from './auth/isLocalAuth';

export function Login({ history }) {
  return isLocalAuth()
    ? <SimpleLogin history={history} />
    : <Auth0Login />;
}

export function Auth0Login() {
  // @ts-expect-error ts(2339) FIXME: Property 'loginWithRedirect' does not exist on type 'unknown'.
  const { loginWithRedirect, error } = useAuthContext();
  const locale = getLocaleCookie() || DEFAULT_LOCALE;

  useEffect(
    () => {
      if (error) {
        // If first login threw an error we force the user to login again on auth0.
        // max_age: 0 clears the auth0 session and forces the user to login again.
        loginWithRedirect({
          authorizationParams: {
            max_age: 0,
            ui_locales: locale,
          },
        });
      } else {
        loginWithRedirect({
          authorizationParams: {
            ui_locales: locale,
          },
        });
      }
    },
    [error, locale, loginWithRedirect],
  );

  return null;
}
