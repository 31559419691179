import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Paragraph, Success } from './text';
import LinkButton from './LinkButton';
import Link from './Link';
import { useAuthContext, useResettingState } from './hooks';
import { useApi } from './ApiProvider';

const LoginLink = styled(Link)`
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  color: ${props => props.theme.colors.primary};
  font-size: inherit;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primaryDark};
  }
`;

const ResendEmailVerification = () => {
  const api = useApi();
  // @ts-expect-error ts(2339) FIXME: Property 'isAuthenticated' does not exist on type 'unknown'.
  const { isAuthenticated } = useAuthContext();
  const [wasResent, setResent] = useResettingState(false, 5000);
  const { t } = useTranslation('onboarding');

  return (
    <>
      <Paragraph>
        <span>{t('emailVerification.needAnotherEmail')}</span>
        {' '}
        {isAuthenticated ? (
          <LinkButton
            onClick={() => { api.sendVerifyEmail().then(() => setResent(true)); }}
            data-test="resend-email-verification"
          >
            {t('emailVerification.sendItAgain')}
          </LinkButton>
        ) : (
          <LoginLink to="/">
            {t('emailVerification.loginToSendItAgain')}
          </LoginLink>
        )}
      </Paragraph>
      {wasResent && (
        <Success style={{ display: 'block' }}>
          <FontAwesomeIcon icon="check-circle" /> {t('emailVerification.emailSent')}
        </Success>
      )}
    </>
  );
};

export default ResendEmailVerification;
