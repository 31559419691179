import { useState, useEffect, ReactNode } from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CompanyMinimized } from '@deepstream/common/rfq-utils';

import { Heading, Paragraph } from './text';
import Field from './Field';
import Button from './Button';
import ButtonLink from './ButtonLink';
import UnexpectedError from './UnexpectedError';
import * as Layout from './Layout';
import ErrorMessage from './ErrorMessage';
import { useApi } from './ApiProvider';

const JoinCompany = withRouter(({ match, history }) => {
  const api = useApi();
  const { t } = useTranslation('onboarding');

  const { companyId } = match.params;

  const [company, setCompany] = useState<CompanyMinimized | null>(null);
  const [error, setError] = useState<ReactNode | null>(null);

  // This is wrong, shouldn't have to fetch the company since we just had
  // it in the "Find company" page. We need to store fetched companies at a
  // higher level so they can persist across route changes
  useEffect(
    () => {
      if (companyId) {
        api.getCompany(companyId).then(setCompany);
      }
    },
    [companyId, api],
  );

  if (!company) {
    return null;
  }

  return (
    <Layout.Page wide title={t('joinCompany.joinCompany')}>
      <Heading>{t('joinCompany.joinCompany')}</Heading>
      <Paragraph>
        {t('joinCompany.companyAlreadyActive', { companyName: company.name })}
      </Paragraph>
      <Formik
        initialValues={{
          jobTitle: '',
          message: '',
        }}
        validationSchema={
          yup.object().shape({
            jobTitle: yup.string().required(t('required', { ns: 'general' })),
            message: yup.string().notRequired(),
          })
        }
        onSubmit={async (values, { setSubmitting }) => {
          try {
            await api.requestCompanyMembership(company, values.jobTitle, values.message);
            history.push('/verification');
          } catch (error) {
            setError(<UnexpectedError />);
            setSubmitting(false);
          }
        }}
      >
        {() => (
          <Form>
            <ErrorMessage panel error={error} />
            <Field label={t('general.yourRole')} name="jobTitle" isRequired />
            <Field label={t('message', { ns: 'general' })} name="message" type="textarea" />
            <Layout.Row justify="space-between">
              <ButtonLink secondary to="/find-company" iconL="arrow-left">
                {t('back', { ns: 'general' })}
              </ButtonLink>
              <Button type="submit">{t('joinCompany.sendRequest')}</Button>
            </Layout.Row>
          </Form>
        )}
      </Formik>
    </Layout.Page>
  );
});

export default JoinCompany;
