import { useContext } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Row, Column, Cell } from './Layout';
import DeviceContext from './DeviceContext';

const COMPANY_LOGO_PLACEHOLDER_URL = 'https://s3-eu-west-1.amazonaws.com/ek-public/common/empty-logo.png';

const PinRight = styled(Column)`
  white-space: nowrap;
`;

const buttonStyles = css<{ selected?: boolean }>`
  display: block;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  // @ts-expect-error ts(2339) FIXME: Property 'selected' does not exist on type 'ThemedStyledProps<object, any>'.
  border: ${props => props.theme.border.width} solid ${props => props.selected ? props.theme.colors.primary : props.theme.colors.lightGray};
  border-radius: ${props => props.theme.border.radii.sharp};
  padding: 20px;
  text-align: left;
  text-decoration: none;
  margin-bottom: 20px;
`;

const StyledDiv = styled.div`
  ${buttonStyles}
`;

const StyledButton = styled.button`
  ${buttonStyles}
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${buttonStyles}
  cursor: pointer;
`;

const Heading = styled.div<{ selected?: boolean }>`
  // @ts-expect-error ts(2339) FIXME: Property 'selected' does not exist on type 'ThemedStyledProps<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ref?: ((instance: HTMLDivElement | null) => void) | ... 2 more ... | undefined; }, any>'.
  color: ${props => props.selected ? props.theme.colors.primary : props.theme.colors.text};
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Description = styled.div`
  color: ${props => props.theme.colors.gray};
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  max-width:100%;
  max-height:100%;
  font-size: 12px;
`;

const ColumnTruncate = styled(Column)`
  overflow: hidden;
  flex: 1;
  margin-right: 15px;
`;

const ImageCell = styled(Cell)`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ExtraSmallLayout = ({ image, heading, description, children }) => (
  <>
    <Row justify="center">
      <ImageCell>
        {image}
      </ImageCell>
    </Row>
    <Row justify="center">
      {heading}
    </Row>
    <Row justify="center">
      {description}
    </Row>
    {children && (
      <Row justify="center" style={{ marginTop: 10 }}>
        <Column justify="center">
          {children}
        </Column>
      </Row>
    )}
  </>
);

const NormalLayout = ({ image, heading, description, children }) => (
  <Row align="center" style={{ position: 'relative' }}>
    <Column justify="center">
      <ImageCell style={{ marginRight: 15 }}>
        {image}
      </ImageCell>
    </Column>
    <ColumnTruncate justify="center">
      {heading}
      {description}
    </ColumnTruncate>
    {children && (
      <PinRight justify="flex-end">
        {children}
      </PinRight>
    )}
  </Row>
);

// Behaves like a Link if the `to` prop is provided
const DescriptionButton = ({ to, onClick, disabled, label, description, selected, imageUrl, children }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'isExtraSmall' does not exist on type 'unknown'.
  const { isExtraSmall } = useContext(DeviceContext);

  const LayoutComponent = isExtraSmall
    ? ExtraSmallLayout
    : NormalLayout;

  const content = (
    <LayoutComponent
      image={<Image alt={`${label} logo`} src={imageUrl || COMPANY_LOGO_PLACEHOLDER_URL} />}
      heading={<Heading selected={selected}>{label}</Heading>}
      description={<Description>{description}</Description>}
      children={children} // eslint-disable-line react/no-children-prop
    />
  );

  if (disabled) {
    return (
      <StyledDiv>
        {content}
      </StyledDiv>
    );
  } else if (to) {
    return (
      <StyledButtonLink to={to} selected={selected}>
        {content}
      </StyledButtonLink>
    );
  } else {
    return (
      <StyledButton type="button" onClick={onClick} selected={selected}>
        {content}
      </StyledButton>
    );
  }
};

export default DescriptionButton;
