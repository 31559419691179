import { useHistory, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import LogOutButton from './LogOutButton';
import Logo from './Logo';
import * as Text from './text';
import { useAuthContext, useDeviceSize } from './hooks';
import { LoginButton } from './LoginButton';
import Button from './Button';
import { isLocalAuth } from './auth/isLocalAuth';

const HeaderContainer = styled.header`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 65px;
  width: 100%;
  z-index: 3;
  right: 0;
  border-bottom: 1px solid ${props => props.theme.colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.theme.colors.white};
`;

function SignUpButton() {
  const history = useHistory();
  const { t } = useTranslation('onboarding');

  return (
    <Button
      type="button"
      small
      outline
      onClick={() => history.push(('/signup'))}
    >
      {t('general.signUp')}
    </Button>
  );
}

const AlreadyHaveAccount = () => {
  const { isExtraSmall } = useDeviceSize();
  const { t } = useTranslation('onboarding');

  return (
    <div>
      {!isExtraSmall && (
        <Text.Subtle small>
          {t('header.alreadyHaveDeepStreamAccount')}
        </Text.Subtle>
      )}
      <LoginButton
        small
        outline
        style={{ marginLeft: 9 }}
      />
    </div>
  );
};

const AuthAction = withRouter(({ match }) => {
  // @ts-expect-error ts(2339) FIXME: Property 'isAuthenticated' does not exist on type 'unknown'.
  const { isAuthenticated } = useAuthContext();

  const isSignUpPage = match.path === '/signup';
  const isLogInPage = match.path === '/login';

  /* eslint-disable no-nested-ternary */

  return (
    <div>
      {isSignUpPage ? (
        <AlreadyHaveAccount />
      ) : isLogInPage && isLocalAuth() ? (
        <SignUpButton />
      ) : isAuthenticated ? (
        <LogOutButton />
      ) : null}
    </div>
  );
});

const Header = () => {
  const { isExtraSmall, isSmall } = useDeviceSize();

  return (
    <HeaderContainer style={{ padding: isSmall || isExtraSmall ? '0px 20px' : '0px 40px' }}>
      <Logo />
      <AuthAction />
    </HeaderContainer>
  );
};

export default Header;
